<div class="full-height">
    <div class="empty-screen" fxLayout="column" fxLayoutAlign="center center">
        <div class="empty-screen-img">
            <img [src]="imageList[screenNameImg]" alt="">
        </div>
        <h4>{{screenName}}</h4>
        <p class="text-s text-center" [innerHTML]="description"></p>

        <div class="text-center" *ngIf="btnLabel != ''">
            <button (click)="goToRedirect()" class="fixed-btn" type="button" mat-raised-button
                color="primary">{{btnLabel}}</button>
        </div>
    </div>
</div>