import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAge'
})
export class DateAgePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {

    const createdDate = new Date(value * 1000).setHours(0, 0, 0, 0) / 1000;
    const epoche = new Date().getTime() / 1000;
    const totalDays = Math.floor((epoche - createdDate) / (60 * 60 * 24));
    if (totalDays !== 0 && !totalDays) {
      return "-";
    }
    return (totalDays <= 1) ? (totalDays + " Day") : (totalDays + " Days");

  }

}
