<h2 class="text-center">Hey, Fix the following errors and
  <br> Try Again!
</h2>

<br>
<ul>
  <li role="listitem" *ngFor="let error of data?.errors; let i = index">{{i+1}}. {{ error }}</li>
</ul>
<br>
<br>

<div fxLayout="row" fxLayoutGap="10px">
  <button fxFlex [mat-dialog-close]="'closed'" type="button" mat-raised-button tabindex="2">Cancel</button>
  <button fxFlex (click)="close()" mat-raised-button type="button" mat-raised-button color="warn"
    tabindex="-1">Okay</button>
</div>
