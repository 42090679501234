import { Pipe, PipeTransform } from '@angular/core';
import { SharedDataService } from '../services/shared-data.service';

@Pipe({
  name: 'alarmType'
})
export class AlarmTypePipe implements PipeTransform {

  constructor(
    private sharedData: SharedDataService
  ) {

  }
  transform(value: unknown): unknown {
    console.log(value);
    if (value == 'unknown') {
      return 'Unknown';
    }
    return this.sharedData.getAttribute('applicationConstant')?.alarm_type.filter((alarm: any) => {
      return alarm.value == value;
    })[0].display_value;
  }
}
