<mat-toolbar
  class="dialog-top-bar"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>
    <button
      (click)="close()"
      mat-icon-button
      class="example-icon"
      aria-label="Example icon-button with menu icon"
    >
      <mat-icon style="color: white">close</mat-icon>
    </button>
  </div>
  <div class="title">Global Filters</div>
  <div></div>
</mat-toolbar>

<div fxLayout="row">
  <div fxFlex="70" class="center-space-border">
    <div class="scroll-section">
      <greyter-header [class]="['', 'white']" [isButtonShow]="false">
        <ng-container id="headerLabel">Pick Location</ng-container>
        <!-- <ng-container id="buttonLabel">Add User</ng-container> -->
      </greyter-header>

      <mat-radio-group
        class="mt-2"
        fxLayout="row"
        fxLayoutAlign="start center"
        aria-labelledby="example-radio-group-label"
        [(ngModel)]="favoriteSeason"
      >
        <mat-radio-button fxFlex="50" value="season">
          Pick Location
        </mat-radio-button>
        <mat-radio-button fxFlex="50" value="season1">
          Pick A Community
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field class="mt-1 full-width display-block">
        <mat-label> Enter City Or State</mat-label>
        <input matInput placeholder="Enter City Or State" />
      </mat-form-field>

      <div>
        <div class="custom-chip space-right">
          <div
            class="text-content"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>Mumbai, India</div>
            <mat-icon>close</mat-icon>
          </div>
        </div>
        <div class="custom-chip space-right">
          <div
            class="text-content"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div>Mumbai, India</div>
            <mat-icon>close</mat-icon>
          </div>
        </div>
      </div>

      <greyter-header [class]="['mt-2', 'white']" [isButtonShow]="false">
        <ng-container id="headerLabel">Pick A System Status</ng-container>
        <!-- <ng-container id="buttonLabel">Add User</ng-container> -->
      </greyter-header>

      <mat-form-field class="full-width mt-1">
        <mat-label>Select an option</mat-label>
        <mat-select disableRipple>
          <mat-option value="1">Option 1</mat-option>
          <mat-option value="2">Option 2</mat-option>
          <mat-option value="3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>

      <greyter-header [class]="['mt-2', 'white']" [isButtonShow]="false">
        <ng-container id="headerLabel">Pick A System</ng-container>
        <!-- <ng-container id="buttonLabel">Add User</ng-container> -->
      </greyter-header>

      <mat-form-field class="full-width mt-1">
        <mat-label>Select an option</mat-label>
        <mat-select disableRipple>
          <mat-option value="1">Option 1</mat-option>
          <mat-option value="2">Option 2</mat-option>
          <mat-option value="3">Option 3</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="btn-space" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-stroked-button color="warn" class="btn-lg">Cancel</button>
      <button mat-raised-button color="warn" class="btn-lg">Set Filter</button>
    </div>
  </div>
  <div fxFlex="30">
    <div class="left-section">
      <greyter-header [class]="['', 'white']" [isButtonShow]="false">
        <ng-container id="headerLabel">Select Time</ng-container>
        <!-- <ng-container id="buttonLabel">Add User</ng-container> -->
      </greyter-header>

      <mat-radio-group
        class="mt-2"
        fxLayout="row"
        fxLayoutAlign="start center"
        aria-labelledby="example-radio-group-label"
        [(ngModel)]="favoriteSeason"
      >
        <mat-radio-button fxFlex="50" value="season"> Range </mat-radio-button>
        <mat-radio-button fxFlex="50" value="season1">
          Custom
        </mat-radio-button>
      </mat-radio-group>

      <div class="mt-3">
        <label for="" class="date-label">Select From Date</label>
        <mat-form-field class="full-width display-block">
          <mat-label>From Day</mat-label>
          <input disabled matInput [matDatepicker]="picker" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>

        <label for="" class="date-label">Select To Date</label>
        <mat-form-field class="full-width display-block">
          <mat-label>To Day</mat-label>
          <input disabled matInput [matDatepicker]="picker1" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="mt-3">
        <ul class="no-style filter">
          <li class="active">
            <div class="circle"></div>
            Today
          </li>
          <li>
            <div class="circle"></div>
            Past 7 Days
          </li>
          <li>
            <div class="circle"></div>
            Past 30 Days
          </li>
          <!-- <li>
                        <div class="circle"></div>Today
                    </li> -->
        </ul>
      </div>
    </div>
    <div class="btn-space" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-stroked-button color="warn" class="btn-lg">Reset</button>
    </div>
  </div>
</div>
