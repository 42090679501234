<!--Toolbar for the app-->
<mat-toolbar class="app-topbar" fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon color="primary" (click)="sidenav.toggle()">menu</mat-icon>
      </button>
      <img src="../../../assets/images/logo.png" alt="brand-logo" class="brand-logo-image" />
    </div>
  </div>
  <div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <!-- <button (click)="openFilter()" mat-icon-button class="example-icon"
        aria-label="Example icon-button with menu icon">
        <mat-icon class="header-icon" svgIcon="icon-ico-setting"></mat-icon>
      </button>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon class="header-icon" svgIcon="icon-ico-notification"></mat-icon>
      </button> -->
      <div>
        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
          <button mat-icon-button class="example-icon cursor-point" aria-label="Example icon-button with menu icon" [matMenuTriggerFor]="menu">
            <mat-icon class="header-icon" svgIcon="icon-ico-profile"></mat-icon>
          </button>
          <div class="profile-name cursor-point" [matMenuTriggerFor]="menu">
            Welcome, {{ userDetail | fullName }}
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/admin/users-profile/profile-info']">
              <span>My Profile</span>
            </button>
            <button mat-menu-item [routerLink]="['/auth/change-password']">
              <span>Change Password</span>
            </button>
            <div flex fxLayout="row" fxLayoutAlign="center center" class="toggle-section gallon-padding">
              <p class="view">View In Gallons</p>
              <mat-slide-toggle [checked]="userDetail.metrics=='gallon'" color="primary"
                (change)="toggleChanged($event.checked)"></mat-slide-toggle>
            </div>
            <button mat-menu-item (click)="logout()">
              <span>Logout</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
<mat-sidenav-container class="posR mat-sidenav-container" fxLayout="row" style="height: calc(100% - 64px)">
  <mat-sidenav #sidenav opened="true" mode="side">
    <mat-nav-list>
      <ng-container *ngFor="let menu of menus">
        <a mat-list-item class="list-items" *ngIf="menu.isShow" routerLinkActive="active" routerLink="{{ menu.link }}">
          <mat-icon class="space-right" svgIcon="{{ menu.iconName }}"></mat-icon>
          {{ menu.name }}
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fxFlex>
    <ngx-ui-loader loaderId="container-loader"></ngx-ui-loader>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>