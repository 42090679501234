import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private nav: NavigationService
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    console.log('Checking If user is already login.');
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {

    if (!this.auth.isLoggedIn()) {
      return true;
    }

    // Navigate to the login page. If it is already login.
    this.nav.navigateTo(['/admin/dashboard']);
    return false;
  }

}
