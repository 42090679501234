
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { DialogService } from './dialog.service';
import { forEach } from 'lodash';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { LoadingService } from './loader.service';
import { NotificationService } from './notification.service';
import { SharedDataService } from './shared-data.service';
import { LoadingService } from './loader.service';
// import { ErrorComponent } from '../components/error/error.component';

class Info { url: any; data?: any; isLoader?: any; loaderName?: string;donot_stop_loader?: any }

@Injectable()

export class DataService {

  constructor(
    private dialog: DialogService,
    private http: HttpClient,
    private loadingService: LoadingService,
    private sharedData: SharedDataService,
    private notification: NotificationService
  ) { }
  startLoader(info: Info) {
    // Start loader before API call
    if (info.isLoader !== false) {

      this.loadingService.start(info.loaderName, 'DataService');
    }
  }

  stopLoader(info: Info,fromError?: boolean) {
    // Reset the loader
    if (info.isLoader !== false) {
     
      if(info.donot_stop_loader==true){
if(fromError==true){
  this.loadingService.stop(info.loaderName, 'DataService');

}
      }else{
      this.loadingService.stop(info.loaderName, 'DataService');

      }
    }
  }

  post(info: Info): Observable<Response> {
    this.startLoader(info);
    return this.http.post<Response>(environment.API_URL + info.url, info.data).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }


  // posts(info: Info): Observable<Response> {
  //   this.startLoader(info);

  //   return this.http.post<Response>(environment.API_URL2 + info.url, info.data).pipe(
  //     map((res: Response) => {
  //       return this.extractData(res, info);
  //     }),
  //     catchError((err: Response) => {
  //       return this.handleError(err, info);
  //     })
  //   );
  // }



  put(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http.put<Response>(environment.API_URL + info.url, info.data).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  get(info: Info): Observable<Response> {
    this.startLoader(info);
    return this.http.get<Response>(environment.API_URL + info.url).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  delete(info: Info): Observable<Response> {
    this.startLoader(info);

    return this.http.delete<Response>(environment.API_URL + info.url, { body: info.data }).pipe(
      map((res: Response) => {
        return this.extractData(res, info);
      }),
      catchError((err: Response) => {
        return this.handleError(err, info);
      })
    );
  }

  extractData(res: any, info: Info) {
  
    
      if(info.donot_stop_loader==true){
        if(res?.error){
          this.stopLoader(info,true);
        }
        
      }else{
        this.stopLoader(info);

      }
    // Complete the loader as valid response is recieved
    return res;
  }

  private handleError(errorResponse: Response | any, info: Info) {
    // Reset the loader as the error occured
    this.stopLoader(info,true);

    // Setting all errors in an array for further processing
    let errors: any[] = [];
    forEach(errorResponse.error.error_list, function (array, key) {
      errors = errors.concat(array);
    });

    // Show toast for single error and dialog for multiple
    const errorsLen = errors.length;
    if (errorsLen === 1) {
      this.notification.toast(errors[0] || errorResponse.statusText);
    } else {

      // this.dialog.openDialogComponent(ErrorComponent, { class: [], errors: errors });
    }
    if (errorResponse.status === 422) {
      // Collect all errors from the error response
      forEach(errorResponse.error.error_list, function (array, key) {
        errors = errors.concat(array);
      });
     
      if (errors.length > 0) {
        errors.forEach(err => this.notification.toast(err, 'danger'));
      }
    } 

    return throwError(errorResponse);
  }



  postProgress(info: Info) {
    console.log(info.data);
    this.startLoader(info);
    return this.http.post(environment.API_URL + info.url, info.data, { reportProgress: true, observe: 'events' });
  }

}
