import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export enum EmptyScreenImages {
  empty = 'assets/images/icons/empty-screen.png'
}
@Component({
  selector: 'app-empty-screen',
  templateUrl: './empty-screen.component.html',
  styleUrls: ['./empty-screen.component.scss']
})
export class EmptyScreenComponent implements OnInit {

  @Input() screenName: any;
  @Input() screenNameImg!: string;
  @Input() description: any;
  @Input() btnLabel: any;
  @Output() redirect: EventEmitter<any> = new EventEmitter();

  imageList: any;

  constructor() {
    this.imageList = EmptyScreenImages;
   }

  ngOnInit(): void {
    // console.log(this.screenName, this.screenNameImg, this.description);
  }

  goToRedirect() {
    this.redirect.emit();
  }
}
