import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(data: any): unknown {

    if (!data?.first_name || data?.first_name == '') {
      return '-';
    }

    return data?.first_name[0].toUpperCase() + '' + data?.last_name[0].toUpperCase();
  }

}
