import { Injectable } from '@angular/core';

import { SharedDataService } from './shared-data.service';

@Injectable()
export class AppLoadService {

  constructor(private sharedData: SharedDataService) { }

  restoreSharedData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.sharedData.restoreSharedData();
      resolve('resolve');
    });
  }

}
